@import url('https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:wght@300&display=swap');

.App {
  text-align: center;
  font-family: 'Open Sans Condensed', sans-serif;
  font-size: calc(10px + 1.7vmin);
}

.App-logo {
  width: 100%;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #ffffff;
  min-height: 70vh;
  max-width:300px;
  width:50vw;
  float: none;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.App-link {
  color: #61dafb;
}


